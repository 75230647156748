import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";

const CreatePrompt = () => {
    const [prompt, setPrompt] = useState({
        name: "",
        description: "",
        prompt: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrompt((prevPrompt) => ({
            ...prevPrompt,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await api.post("/prompts/list", prompt);
            alert("Prompt created successfully");
            navigate("/prompts");  // Redirect to the list of prompts after creation
        } catch (error) {
            console.error("Failed to create prompt", error);
        }
        setIsLoading(false);
    };

    return (
        <Container>
            {isLoading && <p>Creating prompt...</p>}
            {!isLoading && (
                <>
                    <h1>Create New Prompt</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Name
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={prompt.name}
                                    onChange={handleChange}
                                    placeholder="Enter prompt name"
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Description
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    value={prompt.description}
                                    onChange={handleChange}
                                    placeholder="Enter prompt description"
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Prompt
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    name="prompt"
                                    value={prompt.prompt}
                                    onChange={handleChange}
                                    placeholder="Enter the prompt text"
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Button type="submit" variant="primary">
                            Create
                        </Button>
                    </Form>
                </>
            )}
        </Container>
    );
};

export default CreatePrompt;
