import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table, Dropdown, Modal } from "react-bootstrap";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import api from "../../utils/api";

const List = () => {
  const [listReports, setListReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  // Fetch reports when the component is mounted
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get("/report/all");
        setListReports(response.data); // Adjust based on your API structure
      } catch (error) {
        console.error("Failed to fetch reports", error);
      }
    };

    fetchReports();
  }, []);

  // Handle delete button click
  const handleDeleteClick = (report) => {
    setReportToDelete(report);
    setShowModal(true); // Show confirmation modal
  };

  // Confirm and delete the report
  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/report/${reportToDelete.id}`); // Call delete API
      setListReports(listReports.filter((report) => report.id !== reportToDelete.id)); // Update the state
      setShowModal(false); // Close modal
    } catch (error) {
      console.error("Failed to delete report", error);
    }
  };

  // Cancel the delete operation
  const handleCancelDelete = () => {
    setReportToDelete(null);
    setShowModal(false);
  };

  return (
    <Container>
      <h1 className="mt-4">
        All Projects{" "}
        <Button href="/report/create" variant="outline-primary">
          Add
        </Button>
      </h1>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Customer</th>
            <th>Type</th>
            <th>Created</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {listReports.map((report) => (
            <tr key={report.id}>
              <td>{report.name}</td>
              <td>{report.customer.customer_name}</td>
              <td>{report.type}</td>
              <td>
                <UserFriendlyDate timestamp={report.created_at} />
              </td>
              <td>{report.state}</td>
              <td width="120px">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={`/report/view/${report.id}`}>
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => handleDeleteClick(report)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this report?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default List;
