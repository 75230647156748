import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Button, Form } from "react-bootstrap";
import api from "../../utils/api";
import { useParams } from "react-router-dom";

const UserView = () => {
    let { id } = useParams();

    const [userDetail, setUserDetail] = useState({
        name: "",
        email: "",
        role: "",
        is_active: false,
        email_confirmed: false,
    });

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get(`/users/${id}`);
                console.log(response.data);
                setUserDetail(response.data);
            } catch (error) {
                console.error("Failed to fetch user", error);
            }
        };

        fetchUser();
    }, [id]);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetail({
            ...userDetail,
            [name]: value,
        });
    };

    const handleSave = async () => {
        try {
            await api.put(`/users/${id}`, userDetail);
            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update user", error);
        }
    };

    return (
        <Container>
            <h1 className="mt-4">
                User Details
                {userDetail.is_active ? (
                    <Badge bg="primary" className="ms-2">Active</Badge>
                ) : (
                    <Badge bg="secondary" className="ms-2">Inactive</Badge>
                )}
            </h1>
            <Row className="py-2">
                <Col>
                    <strong>Name: </strong>
                    {isEditing ? (
                        <Form.Control
                            type="text"
                            name="name"
                            value={userDetail.name}
                            onChange={handleChange}
                        />
                    ) : (
                        userDetail.name
                    )}
                </Col>
                <Col>
                    <strong>Email: </strong>
                    {isEditing ? (
                        <Form.Control
                            type="email"
                            name="email"
                            value={userDetail.email}
                            onChange={handleChange}
                        />
                    ) : (
                        userDetail.email
                    )}
                </Col>
            </Row>
            <Row className="py-2">
                <Col>
                    <strong>Role: </strong>
                    {isEditing ? (
                        <Form.Control
                            type="text"
                            name="role"
                            value={userDetail.role}
                            onChange={handleChange}
                        />
                    ) : (
                        userDetail.role
                    )}
                </Col>
                <Col>
                    <strong>Email Confirmed: </strong>
                    {isEditing ? (
                        <Form.Check
                            type="checkbox"
                            label="Email Confirmed"
                            name="email_confirmed"
                            checked={userDetail.email_confirmed}
                            onChange={(e) =>
                                setUserDetail({
                                    ...userDetail,
                                    email_confirmed: e.target.checked,
                                })
                            }
                        />
                    ) : userDetail.email_confirmed ? (
                        <Badge bg="success">Yes</Badge>
                    ) : (
                        <Badge bg="danger">No</Badge>
                    )}
                </Col>
            </Row>
            <Row className="py-2">
                <Col>
                    <strong>Is Active: </strong>
                    {isEditing ? (
                        <Form.Check
                            type="checkbox"
                            label="Active"
                            name="is_active"
                            checked={userDetail.is_active}
                            onChange={(e) =>
                                setUserDetail({
                                    ...userDetail,
                                    is_active: e.target.checked,
                                })
                            }
                        />
                    ) : userDetail.is_active ? (
                        <Badge bg="primary">Active</Badge>
                    ) : (
                        <Badge bg="secondary">Inactive</Badge>
                    )}
                </Col>
            </Row>

            {/* Button to toggle edit mode and save changes */}
            <Row className="mt-4">
                <Col>
                    {isEditing ? (
                        <>
                            <Button variant="success" onClick={handleSave}>
                                Save Changes
                            </Button>
                            <Button variant="secondary" className="ms-2" onClick={handleEditToggle}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button variant="primary" onClick={handleEditToggle}>
                            Edit User
                        </Button>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default UserView;
