import axios from 'axios';

// Get the API base URL from environment variables
const API_BASE_URL = 'https://lifemind-agents-becf34adf8d0.herokuapp.com'
//const API_BASE_URL = 'http://localhost:8090'

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
});

api.defaults.headers.common['access-key'] = `fdadfal21l3e`;

// Export the Axios instance for making API calls
export default api;
