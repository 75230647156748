import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import api from "../../utils/api";
import { useParams } from "react-router-dom";

const CustomerView = () => {
    let { customer_id } = useParams();

    const [customerDetail, setCustomerDetail] = useState({
        customer_name: "",
        created_at: ""
    });

    const [isEditing, setIsEditing] = useState(false);
    const [updatedCustomerName, setUpdatedCustomerName] = useState("");

    // Fetch customer details on mount
    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await api.get(`/customer/${customer_id}`);
                setCustomerDetail(response.data);
                setUpdatedCustomerName(response.data.customer_name);
            } catch (error) {
                console.error("Failed to fetch customer details", error);
            }
        };

        fetchCustomer();
    }, [customer_id]);

    // Toggle editing mode
    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    // Save updated customer details
    const handleSave = async () => {
        try {
            const updatedCustomer = {
                customer_name: updatedCustomerName
            };

            // Send the updated customer details via API
            const response = await api.patch(`/customer/${customer_id}`, updatedCustomer);
            setCustomerDetail(response.data); // Update the view with the saved data
            setIsEditing(false); // Exit edit mode
        } catch (error) {
            console.error("Failed to save customer details", error);
        }
    };

    return (
        <Container>
            <h1 className="mt-4">Customer Details</h1>
            <Row className="py-2">
                <Col>
                    <strong>Name: </strong>
                    {isEditing ? (
                        <Form.Control
                            type="text"
                            value={updatedCustomerName}
                            onChange={(e) => setUpdatedCustomerName(e.target.value)}
                        />
                    ) : (
                        customerDetail.customer_name
                    )}
                </Col>
            </Row>
            <Row className="py-2">
                <Col>
                    <strong>Created At: </strong> {new Date(customerDetail.created_at).toLocaleString()}
                </Col>
            </Row>

            {/* Toggle between Edit and Save/Cancel buttons */}
            <Row className="mt-4">
                <Col>
                    {isEditing ? (
                        <>
                            <Button variant="success" onClick={handleSave}>
                                Save Changes
                            </Button>
                            <Button variant="secondary" className="ms-2" onClick={handleEditToggle}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button variant="primary" onClick={handleEditToggle}>
                            Edit Customer
                        </Button>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default CustomerView;
