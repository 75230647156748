import React, { useState } from "react";
import { useAuth } from "./AuthContext";
import {
  Person,
  PersonGear,
  GraphUp,
  BoxArrowRight,
  PlusCircle,
  ChatLeftText,
  CurrencyDollar,
  Robot,
  ChevronRight
} from "react-bootstrap-icons";

const LeftNav = () => {
  const { logout } = useAuth();
  const [lifemindExpanded, setLifemindExpanded] = useState(false);
  const [projectsExpanded, setProjectsExpanded] = useState(false);
  const [leadsExpanded, setLeadsExpanded] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  const toggleLifemind = () => {
    setLifemindExpanded(!lifemindExpanded);
  };

  const toggleProjects = () => {
    setProjectsExpanded(!projectsExpanded);
  };

  const toggleLeads = () => {
    setLeadsExpanded(!leadsExpanded);
  };

  return (
    <div id="sidebar-wrapper" style={{ width: "250px" }}>
      <div className="list-group list-group-flush">
        <a
          href="/users/list"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <PersonGear className="bi me-2" size={20} /> Users
        </a>

        {/* Lifemind Section */}
        <a
          onClick={toggleLifemind}
          className="list-group-item list-group-item-action d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <ChevronRight
            className="bi me-2"
            size={20}
            style={{ transform: lifemindExpanded ? "rotate(90deg)" : "none" }}
          />
          Lifemind
        </a>

        {lifemindExpanded && (
          <div className="ps-4">
            <a
              href="/customer/list"
              className="list-group-item list-group-item-action d-flex align-items-center"
            >
              <Person className="bi me-2" size={20} /> Customers
            </a>
            <a
              onClick={toggleProjects}
              className="list-group-item list-group-item-action d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <ChevronRight
                className="bi me-2"
                size={20}
                style={{ transform: projectsExpanded ? "rotate(90deg)" : "none" }}
              />
              Projects
            </a>

            {projectsExpanded && (
              <div className="ps-4">
                <a
                  href="/report/list"
                  className="list-group-item list-group-item-action d-flex align-items-center"
                >
                  <GraphUp className="bi me-2" size={20} /> All Projects
                </a>
                <a
                  href="/"
                  className="list-group-item list-group-item-action d-flex align-items-center"
                >
                  <PlusCircle className="bi me-2" size={20} /> New Project
                </a>
              </div>
            )}
            <a
              href="/workflows/"
              className="list-group-item list-group-item-action d-flex align-items-center"
            >
              <Robot className="bi me-2" size={20} /> Workflows
            </a>
          </div>
        )}

        {/* Lead Assessment and Leads Section */}
        <a
          onClick={toggleLeads}
          className="list-group-item list-group-item-action d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <ChevronRight
            className="bi me-2"
            size={20}
            style={{ transform: leadsExpanded ? "rotate(90deg)" : "none" }}
          />
          Leads
        </a>

        {leadsExpanded && (
          <div className="ps-4">
            <a
              href="/leads/list"
              className="list-group-item list-group-item-action d-flex align-items-center"
            >
              <CurrencyDollar className="bi me-2" size={20} /> Leads
            </a>
            <a
              href="/prompts/list"
              className="list-group-item list-group-item-action d-flex align-items-center"
            >
              <ChatLeftText className="bi me-2" size={20} /> Prompts
            </a>
          </div>
        )}

        <a
          as="button"
          onClick={handleLogout}
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <BoxArrowRight className="bi me-2" size={20} /> Logout
        </a>
      </div>
    </div>
  );
};

export default LeftNav;
