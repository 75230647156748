import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import api from "../../../utils/agentApi";

function ViewRun() {
  let { runId } = useParams(); // Get the run ID from the URL parameters

  const [run, setRun] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [agentName, setAgentName] = useState(""); // To hold the agent's name

  useEffect(() => {
    const fetchRun = async () => {
      setIsLoading(true);
      try {
        // Fetch the run details
        const response = await api.post(`/get_run`, { run_id: runId });
        const runData = response.data.data;
        setRun(runData);

        // Fetch the agent's name using the agent_id from the run
        const agentResponse = await api.post(`/get_agent`, { agent_id: runData.agent_id });
        const agentData = agentResponse.data.data;
        setAgentName(agentData.name);
      } catch (error) {
        console.error("Error fetching run details:", error);
      }
      setIsLoading(false);
    };

    fetchRun();
  }, [runId]);

  return (
    <Container>
      {isLoading && <p>Loading run...</p>}
      {!isLoading && run && (
        <>
          <h1>Run Details</h1>

          <Row>
            <Col xs={2}>
              <strong>Run ID: </strong>
            </Col>
            <Col>{run.id}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Created At: </strong>
            </Col>
            <Col>{run.created_at}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>User ID: </strong>
            </Col>
            <Col>{run.user_id}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Agent: </strong>
            </Col>
            <Col>{agentName}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Output: </strong>
            </Col>
            <Col>
              <ReactMarkdown>{run.output}</ReactMarkdown>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ViewRun;
