import React, { useState, useEffect } from "react";
import { Container, Table, Dropdown, Button } from "react-bootstrap";
import api from "../../../utils/agentApi";

function ListRuns() {
  const [runs, setRuns] = useState([]);
  const [agentNames, setAgentNames] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRuns = async () => {
      setIsLoading(true);
      const response = await api.get("/get_user_runs");
      const runsData = response.data.data;
      setRuns(runsData);

      // Fetch agent names
      const agentNameMap = {};
      for (const run of runsData) {
        const agentResponse = await api.post("/get_agent", { agent_id: run.agent_id });
        const agentName = agentResponse.data.data.name;
        agentNameMap[run.agent_id] = agentName;
      }
      setAgentNames(agentNameMap);
      setIsLoading(false);
    };
    fetchRuns();
  }, []);

  const handleDelete = (id) => {
    return async () => {
      console.log(id);
      try {
        let runData = {
          run_id: id,
        };
        await api.post(`/remove_user_run`, runData);
        const response = await api.get("/get_user_runs");
        setRuns(response.data.data);
      } catch (error) {
        alert("Failed to delete run");
      }
    };
  };

  return (
    <Container>
      {isLoading && <p>Loading runs...</p>}
      {runs.length === 0 && !isLoading && <p>No Runs found.</p>}
      <h1>Runs</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Output</th>
            <th>Agent</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {runs.map((run) => (
            <tr key={run.id}>
              <td>{agentNames[run.agent_id] || "Loading..."}</td>
              <td style={{ width: "50%" }}>{run.output}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={`/workflows/runs/view/${run.id}`}>
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleDelete(run.id)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ListRuns;
