import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import UserFriendlyDate from '../../Utils/UserFriendlyDate';

import api from "../../../utils/agentApi";

function ListAgents() {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      const response = await api.get("/get_agents");
      setAgents(response.data.data);
      setIsLoading(false);
    };
    fetchLeads();
  }, []);

  const handleDelete = (id) => {
    return async () => {

      //console.log(id);
      setIsLoading(true);
      try {
        let agentData = {
          "agent_id": id
        };

        const code_exec = await api.post(`/get_code_executions_by_agent`, agentData);
        const code_exec_result = code_exec.data.data;

        if (code_exec_result.length > 0) {
          for (let i = 0; i < code_exec_result.length; i++) {
            let code_exec_data = {
              "exec_id": code_exec_result[i].id
            };
            await api.post(`/remove_code_execution`, code_exec_data);
          }
        }


        await api.post(`/remove_agent`, agentData);
        const response = await api.get("/get_agents");
        setAgents(response.data.data); // Adjust based on your API structure
      } catch (error) {
        console.error("Failed to delete customer", error);
      }

      setIsLoading(false);
    };
  };


  return (
    <Container>
      {isLoading && <p>Loading agents...</p>}
      {agents.length === 0 && !isLoading && <p>No Agents found.</p>}
      <h1>Agents{" "}
        <Button href="/workflows/agents/create" variant="outline-primary">
          New Agent
        </Button></h1>
      <Table striped bordered hover>
        {/*            {
            "id": "d3ccc896-e49b-4c2d-9e5d-419f76c34c6d",
            "created_at": "2024-05-28T16:09:26.617987+00:00",
            "name": "Customer Count",
            "model": "gpt-4o",
            "frequency_penalty": 0,
            "max_tokens": null,
            "presence_penalty": 0,
            "response_format": "text",
            "stream": false,
            "temperature": 0,
            "description": "Customer Count",
            "system_prompt": "",
            "custom_code": "const numberOfRowsExcludingHeader = inputData.length; result = numberOfRowsExcludingHeader;",
            "custom_input": {
                "filePath": "master.xlsx"
            }
        }, */}

        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agents.map((agent) => (
            <tr key={agent.id}>
              <td>{agent.name}</td>
              <td>{agent.description}</td>
              <td>
                {agent.custom_code ? (
                  "Calculation"
                ) : (
                  "AI"
                )}
              </td>
              <td>
                <UserFriendlyDate timestamp={agent.created_at} /></td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={`/workflows/agents/view/${agent.id}`}>
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleDelete(agent.id)} >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ListAgents;
